.dropdown-menu {
  position: absolute;
  top: 0;
  z-index: 1000;
  display: block;
  float: left;
  min-width: 10rem;
  padding: 0;
  margin: 0.125rem;
  font-size: .875rem;
  color: #23282c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;

  .dropdown-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.625rem 1rem;
    clear: both;
    font-weight: 400;
    color: #23282c;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    position: relative;
    border-bottom: 1px solid #c8ced3;

    &:first-child {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px);
    }

    &:hover {
      background: lightgrey;
    }
  }
}