@tailwind base;
@tailwind components;
@tailwind utilities;

@layer keyframes {
    @keyframes pop-open {
      0% {
        transform: scale(0.3);
        opacity: 0;
      }
      75% {
        transform: scale(1.05);
        opacity: 1;
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .popup-menu {
    animation: pop-open 0.1s both;
  }

  /** TODO: REMOVE! This is only for the BHM video demo. You may need to move the signature dropdown to somewhere else */
  .editor-container .Dropdown div.relative div.absolute {
    top: -2rem
  }

  .leaflet-container {
    width: 100%;
    height: 200px;
  }