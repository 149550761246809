.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ReactModal__Content {
  border: none !important;
  border-radius: 1rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ReactModal__Content canvas {
  margin: 0 auto;
  display: block
}

.ReactModal__Content .close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
  padding: 10px
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5
}

.close:hover {
  color: #000;
  text-decoration: none
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer
}

.close:not(:disabled):not(.disabled):focus,.close:not(:disabled):not(.disabled):hover {
  opacity: .75
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

button.close.white {
  background-color: white;
}

a.close.disabled {
  pointer-events: none
}

.grid-forum-table-cols {
  grid-template-columns: 40px minmax(190px, 1fr) 60px 80px 60px 210px;
}

.grid-forum-table-cols-admin {
  grid-template-columns: 40px minmax(190px, 1fr) 60px 80px 60px 210px 50px;
}

.grid-users-table-cols-admin {
  grid-template-columns: minmax(150px, 1fr) minmax(200px, 500px) minmax(100px, 150px) 60px;
}

.hero-banner {
  background-position: center;
  background-size: cover;
}

@media all and (min-width: 1600px) {
  .hero-banner {
    background-size: contain;
  }
}

@media all and (min-width: 1920px) {
  .hero-banner {
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
