#cf-media-slider .slick-slider,
#cf-media-slider .slick-list,
#cf-media-slider .slick-track {
  height: 100%;
}

#cf-modal,
#cf-media-slider .slick-slide > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: black !important;
  height: 100%;
  position: relative;
}

#cf-modal .thread-media-image,
#cf-media-slider .thread-media-image {
  width: auto !important;
  height: auto !important;
  max-height: 100vh;
}
