.MainNav.slim {
    width: 72px;

    & span {
        display: none;
    }

    & .NavItem a {
        display: flex;
        justify-content: center;
    }
}

/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  
  /* Handle */
  div:hover::-webkit-scrollbar-thumb {
    background: rgba(119, 114, 114, 0.712);
    border-radius: 10px;
    
  }