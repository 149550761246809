
.ColourPicker__swash {
    border: 1px solid transparent;
    border-radius: 50%;
    border-color: rgba(0, 0, 0, 0.25);
  }
  .block-colour--white {
    background-color: white;
  }
  
  .block-colour--orange {
    background-color: #fff0db !important;
  }
  
  .block-colour--pink {
    background-color: #ffe4f7 !important;
  }
  
  .block-colour--yellow {
    background-color: #faf785 !important;
  }
  
  .block-colour--red {
    background-color: #ffe5e5 !important;
  }
  
  .block-colour--purple {
    background-color: #f2edff !important;
  }
  
  .block-colour--blue {
    background-color: #e1effc !important;
  }
  
  .block-colour--green {
    background-color: #e4f8e2 !important;
  }
  
  .block-colour--brown {
    background-color: #eee2d7 !important;
  }
  
  .block-colour--grey {
    background-color: #f2f2f2 !important;
  }
  
  .ColourPicker__input:checked ~ .ColourPicker__swash {
    border-color: black;
  }
  
  .ColourPicker__input:checked ~ .ColourPicker__swash::before {
    content: '✓';
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(40, 60, 70);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-weight: 600;
    line-height: 1;
  }
  
  
  
  .FolderBox.foldersvg[data-folder-colour="white"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='239' viewBox='0 0 180 239'%3E%3Cpath fill='white' fill-rule='evenodd' d='M4,-1.54980961e-16 L76.3353265,5.45031826e-15 L76.3353265,7.10542736e-15 C78.7053659,-3.85503091e-15 80.853319,1.39512964 81.816971,3.56041516 L88.4388884,18.4395848 L88.4388884,18.4395848 C89.4025404,20.6048704 91.5504935,22 93.9205328,22 L175,22 L175,22 C177.761424,22 180,24.2385763 180,27 L180,235 L180,235 C180,237.209139 178.209139,239 176,239 L4,239 L4,239 C1.790861,239 2.705415e-16,237.209139 0,235 L8.8817842e-16,4 L8.8817842e-16,4 C6.17636919e-16,1.790861 1.790861,4.05812251e-16 4,0 Z'/%3E%3C/svg%3E");
    background-color: #f0f0f0;
  }
  
  
  .FolderBox.foldersvg[data-folder-colour="yellow"] {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='239' viewBox='0 0 180 239'%3E%3Cpath fill='rgb(253,252,206)' fill-rule='evenodd' d='M4,-1.54980961e-16 L76.3353265,5.45031826e-15 L76.3353265,7.10542736e-15 C78.7053659,-3.85503091e-15 80.853319,1.39512964 81.816971,3.56041516 L88.4388884,18.4395848 L88.4388884,18.4395848 C89.4025404,20.6048704 91.5504935,22 93.9205328,22 L175,22 L175,22 C177.761424,22 180,24.2385763 180,27 L180,235 L180,235 C180,237.209139 178.209139,239 176,239 L4,239 L4,239 C1.790861,239 2.705415e-16,237.209139 0,235 L8.8817842e-16,4 L8.8817842e-16,4 C6.17636919e-16,1.790861 1.790861,4.05812251e-16 4,0 Z'/%3E%3C/svg%3E");
      background-color: #f9f568;
  }
  
  .FolderBox.foldersvg[data-folder-colour="orange"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='239' viewBox='0 0 180 239'%3E%3Cpath fill='rgb(255,240,219)' fill-rule='evenodd' d='M4,-1.54980961e-16 L76.3353265,5.45031826e-15 L76.3353265,7.10542736e-15 C78.7053659,-3.85503091e-15 80.853319,1.39512964 81.816971,3.56041516 L88.4388884,18.4395848 L88.4388884,18.4395848 C89.4025404,20.6048704 91.5504935,22 93.9205328,22 L175,22 L175,22 C177.761424,22 180,24.2385763 180,27 L180,235 L180,235 C180,237.209139 178.209139,239 176,239 L4,239 L4,239 C1.790861,239 2.705415e-16,237.209139 0,235 L8.8817842e-16,4 L8.8817842e-16,4 C6.17636919e-16,1.790861 1.790861,4.05812251e-16 4,0 Z'/%3E%3C/svg%3E");
    background-color: #ffe3bc;
  }
  
  .FolderBox.foldersvg[data-folder-colour="red"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='239' viewBox='0 0 180 239'%3E%3Cpath fill='rgb(255,229,229)' fill-rule='evenodd' d='M4,-1.54980961e-16 L76.3353265,5.45031826e-15 L76.3353265,7.10542736e-15 C78.7053659,-3.85503091e-15 80.853319,1.39512964 81.816971,3.56041516 L88.4388884,18.4395848 L88.4388884,18.4395848 C89.4025404,20.6048704 91.5504935,22 93.9205328,22 L175,22 L175,22 C177.761424,22 180,24.2385763 180,27 L180,235 L180,235 C180,237.209139 178.209139,239 176,239 L4,239 L4,239 C1.790861,239 2.705415e-16,237.209139 0,235 L8.8817842e-16,4 L8.8817842e-16,4 C6.17636919e-16,1.790861 1.790861,4.05812251e-16 4,0 Z'/%3E%3C/svg%3E");
    background-color: #ffc6c6;
  }
  
  .FolderBox.foldersvg[data-folder-colour="pink"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='239' viewBox='0 0 180 239'%3E%3Cpath fill='rgb(255,228,247)' fill-rule='evenodd' d='M4,-1.54980961e-16 L76.3353265,5.45031826e-15 L76.3353265,7.10542736e-15 C78.7053659,-3.85503091e-15 80.853319,1.39512964 81.816971,3.56041516 L88.4388884,18.4395848 L88.4388884,18.4395848 C89.4025404,20.6048704 91.5504935,22 93.9205328,22 L175,22 L175,22 C177.761424,22 180,24.2385763 180,27 L180,235 L180,235 C180,237.209139 178.209139,239 176,239 L4,239 L4,239 C1.790861,239 2.705415e-16,237.209139 0,235 L8.8817842e-16,4 L8.8817842e-16,4 C6.17636919e-16,1.790861 1.790861,4.05812251e-16 4,0 Z'/%3E%3C/svg%3E");
    background-color: #ffc5ee;
  }
  
  .FolderBox.foldersvg[data-folder-colour="purple"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='239' viewBox='0 0 180 239'%3E%3Cpath fill='rgb(242,237,255)' fill-rule='evenodd' d='M4,-1.54980961e-16 L76.3353265,5.45031826e-15 L76.3353265,7.10542736e-15 C78.7053659,-3.85503091e-15 80.853319,1.39512964 81.816971,3.56041516 L88.4388884,18.4395848 L88.4388884,18.4395848 C89.4025404,20.6048704 91.5504935,22 93.9205328,22 L175,22 L175,22 C177.761424,22 180,24.2385763 180,27 L180,235 L180,235 C180,237.209139 178.209139,239 176,239 L4,239 L4,239 C1.790861,239 2.705415e-16,237.209139 0,235 L8.8817842e-16,4 L8.8817842e-16,4 C6.17636919e-16,1.790861 1.790861,4.05812251e-16 4,0 Z'/%3E%3C/svg%3E");
    background-color: #dcceff;
  }
  
  .FolderBox.foldersvg[data-folder-colour="blue"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='239' viewBox='0 0 180 239'%3E%3Cpath fill='rgb(225,239,252)' fill-rule='evenodd' d='M4,-1.54980961e-16 L76.3353265,5.45031826e-15 L76.3353265,7.10542736e-15 C78.7053659,-3.85503091e-15 80.853319,1.39512964 81.816971,3.56041516 L88.4388884,18.4395848 L88.4388884,18.4395848 C89.4025404,20.6048704 91.5504935,22 93.9205328,22 L175,22 L175,22 C177.761424,22 180,24.2385763 180,27 L180,235 L180,235 C180,237.209139 178.209139,239 176,239 L4,239 L4,239 C1.790861,239 2.705415e-16,237.209139 0,235 L8.8817842e-16,4 L8.8817842e-16,4 C6.17636919e-16,1.790861 1.790861,4.05812251e-16 4,0 Z'/%3E%3C/svg%3E");
    background-color: #c5e0f9;
  }
  
  .FolderBox.foldersvg[data-folder-colour="green"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='239' viewBox='0 0 180 239'%3E%3Cpath fill='rgb(228,248,226)' fill-rule='evenodd' d='M4,-1.54980961e-16 L76.3353265,5.45031826e-15 L76.3353265,7.10542736e-15 C78.7053659,-3.85503091e-15 80.853319,1.39512964 81.816971,3.56041516 L88.4388884,18.4395848 L88.4388884,18.4395848 C89.4025404,20.6048704 91.5504935,22 93.9205328,22 L175,22 L175,22 C177.761424,22 180,24.2385763 180,27 L180,235 L180,235 C180,237.209139 178.209139,239 176,239 L4,239 L4,239 C1.790861,239 2.705415e-16,237.209139 0,235 L8.8817842e-16,4 L8.8817842e-16,4 C6.17636919e-16,1.790861 1.790861,4.05812251e-16 4,0 Z'/%3E%3C/svg%3E");
    background-color: #cdf2c9;
  }
  
  .FolderBox.foldersvg[data-folder-colour="brown"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='239' viewBox='0 0 180 239'%3E%3Cpath fill='rgb(238,226,215)' fill-rule='evenodd' d='M4,-1.54980961e-16 L76.3353265,5.45031826e-15 L76.3353265,7.10542736e-15 C78.7053659,-3.85503091e-15 80.853319,1.39512964 81.816971,3.56041516 L88.4388884,18.4395848 L88.4388884,18.4395848 C89.4025404,20.6048704 91.5504935,22 93.9205328,22 L175,22 L175,22 C177.761424,22 180,24.2385763 180,27 L180,235 L180,235 C180,237.209139 178.209139,239 176,239 L4,239 L4,239 C1.790861,239 2.705415e-16,237.209139 0,235 L8.8817842e-16,4 L8.8817842e-16,4 C6.17636919e-16,1.790861 1.790861,4.05812251e-16 4,0 Z'/%3E%3C/svg%3E");
    background-color: #e5d2c2;
  }
  
  .FolderBox.foldersvg[data-folder-colour="grey"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='239' viewBox='0 0 180 239'%3E%3Cpath fill='rgb(242,242,242)' fill-rule='evenodd' d='M4,-1.54980961e-16 L76.3353265,5.45031826e-15 L76.3353265,7.10542736e-15 C78.7053659,-3.85503091e-15 80.853319,1.39512964 81.816971,3.56041516 L88.4388884,18.4395848 L88.4388884,18.4395848 C89.4025404,20.6048704 91.5504935,22 93.9205328,22 L175,22 L175,22 C177.761424,22 180,24.2385763 180,27 L180,235 L180,235 C180,237.209139 178.209139,239 176,239 L4,239 L4,239 C1.790861,239 2.705415e-16,237.209139 0,235 L8.8817842e-16,4 L8.8817842e-16,4 C6.17636919e-16,1.790861 1.790861,4.05812251e-16 4,0 Z'/%3E%3C/svg%3E");
    background-color: #e3e3e3;
  }
  
  details.ColourPicker__details > summary {
    list-style: none;
  }
  details.ColourPicker__details > summary::-webkit-details-marker {
    display: none;
  }
  