.pagination-container {
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-top: 16px;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0,0,0,.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: .01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots {
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }

    &:hover {
      background-color: rgba(0,0,0,.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0,0,0,.08);
    }

    .arrow {
      &:before {
        position: relative;
        content: "";
        display: inline-block;
        width: .4em;
        height: .4em;
        border-right: .12em solid rgba(0,0,0,.87);
        border-top: .12em solid rgba(0,0,0,.87);
      }

      &.left {
        -webkit-transform: rotate(-135deg) translate(-50%);
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow {
        &:before {
          border-right: .12em solid rgba(0,0,0,.43);
          border-top: .12em solid rgba(0,0,0,.43);
        }
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
